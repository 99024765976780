@import "../Scss/variables.scss";
@import "../Scss/mixins.scss";

.GreyContainer {
  background-color: rgba(112, 112, 112, 0.1);
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  transition: all 0.3s ease-in-out;
  flex: 1;

  &:hover {
    background-color: var(--color-grey);
  }

  .GreyContainerText {
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
    @include full-width;
  }

  a,
  button {
    color: #8b8b8b;
    display: flex;
    display: -ms-flexbox;
    text-transform: capitalize;
    font-family: $primary-font;
    justify-content: left !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    @include full-width;
    font-size: 1rem;
    font-weight: 500;
    padding: 15px 15px 15px 20px;

    &:hover {
      color: #fff;
    }

    @include respond-laptop {
      padding: 10px;
    }

    @include respond-md-desk {
      font-size: 1rem;
    }
  }
}