@import "../Scss/mixins";

.product-contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-contact {
    color: var(--color-primary) !important;
    cursor: pointer;
    font-size: 0.6rem;
    font-weight: bolder;
  }

  a {
    margin-bottom: 15px;

    img {
      width: 150px;
    }
  }

  .btn-product-contact .MuiSvgIcon-root {
    font-size: 22px;
  }

  button {
    margin-bottom: 15px;

    @include respond-md-desk {
      font-size: 14px !important;

      &:last-child {
        float: right;
      }
    }

    @include respond-md-phone {
      width: 100%;
      margin-bottom: 20px !important;
    }
  }

  .contact-box {
    font-size: 20px;
    width: 100%;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: center;

    h5,
    div {
      margin-bottom: 15px;
    }

    h5 {
      text-transform: uppercase;
      color: var(--color-primary);
      margin-top: 10px;
      font-weight: bold;
    }

    p {
      color: var(--color-grey);
    }

    a,
    button {
      width: 100%;
    }

    a span svg {
      margin-right: 0px;
    }
  }
}