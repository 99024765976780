@import "../Scss/variables";
@import "../Scss/mixins";

.product-description-container {
  .MuiTabs-indicator {
    background-color: var(--color-secondary) !important;
  }

  .Mui-selected {
    color: var(--color-secondary) !important;
  }

  .MuiTabs-flexContainer {
    justify-content: space-between;
    font-family: $primary-font;

    button {
      font-family: $primary-font !important;
      text-transform: capitalize;
      font-weight: normal;
    }
  }

  .product-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--color-primary);
    text-align: left;

    @include respond-md-phone {
      font-size: 22px;
    }
  }

  .product-title {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 20px 0;
    color: var(--color-secondary);
    font-style: italic;

    @include respond-md-desk {
      margin-bottom: 0;
    }

    @include respond-md-phone {
      font-size: 16px;
    }
  }

  table {
    border: none;
    border-collapse: collapse;
  }

  td {
    border-color: var(--color-lightgrey) !important;
    border-collapse: collapse;
  }

  .links-table,
  .files-table {
    border: 1px solid var(--color-lightgrey);
    border-radius: 4px;
    border-collapse: collapse;
  }

  .categories-table {
    p {
      margin: 0px;
      text-align: left;
    }

    .grid-table .item {
      padding: 10px;
      border: 1px solid var(--color-lightgrey);
      margin-bottom: 15px;
      border-radius: 4px;

      & > div:first-child p {
        font-weight: bold;
      }
    }
  }

  .files-table img {
    width: 25px;
    margin-right: 5px;
  }

  .no-content {
    color: var(--color-grey);
    font-style: italic;
    font-weight: 300;
    text-align: center;
  }
  .product-description{
    iframe{
      max-width: 100%;
    }
  }
}
