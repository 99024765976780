.download-container {
  display: flex;
  flex-direction: column;
  padding: 90px 40px !important;
  min-height: calc(100vh - 43px);

  h2 {
    margin-bottom: 0px !important;
  }

  button {
    align-self: center;
  }

  .btn-goback-container {
    margin-bottom: 20px;
    
    svg{
      font-size: 1rem !important;
        margin-left: 5px;
    }
  }

  .MuiTableContainer-root tr td {
    background-color: none !important;
  }
}

.cart-table {
  font-style: italic;

  thead th {
    background-color: var(--color-primary);
    color: white;

    span,
    svg {
      display: inherit;
      color: white;
    }
    &.thead-checkbox {
      span {
        justify-content: center;
        display: flex;
      }
    }
  }

  tr th,
  tr td {
    border: none !important;
    border-bottom: 1px solid var(--color-grey) !important;
  }

  .table-checkbox span {
    color: var(--color-primary);
    svg {
      margin-right: 0;
    }
  }

  .download-product-file {
    font-style: italic;
  }

  .cart-delete-icon {
    color: red;
    margin: 0;
  }

  .cart-fd-icon {
    color: var(--color-primary);
    margin: 0;
  }

  .MuiLoadingButton-loading {
    .MuiLoadingButton-loadingIndicator {
      color: var(--color-primary);

      .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
        width: 26px !important;
        height: 26px !important;
      }
    }

    svg {
      margin-right: 0;
    }
  }

  .table-loader {
    display: flex;
    justify-content: center;
    margin: 30px 0;

    svg {
      margin-right: 0;
    }
  }
}
