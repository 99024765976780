@import "../Scss/mixins";

.search-region-container {
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  justify-content: flex-end;
  float: right;
  width: 50%;

  @include respond-md-phone {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  #openRegionMenu p {
    @include respond-sm-phone {
      display: none;
    }
  }
}

.MuiAutocomplete-option {
  display: block;
  word-break: break-all;

  div {
    display: inline-block;
    word-break: break-all;
  }
}