@import "../Scss/mixins.scss";
@import "../Scss/variables.scss";

.page-not-found {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    .inner-content {
        text-align: center;

        .project-name {
            border-left: 1px solid var(--color-primary);
            margin: 0 10px 0 0;
            display: inline-flex;

            span {
                padding-left: 10px;
                color: var(--color-primary);
                font-weight: 500;
                font-family: "Ubuntu", sans-serif !important;
            }
        }

        .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
            box-shadow: none;

            .logo {
                margin: 0 auto;
                margin-bottom: 30px;
            }

            .MuiTypography-root.MuiTypography-h2 {
                color: var(--color-grey);
                font-size: 2rem !important;
                font-weight: 500 !important;
                margin-bottom: 1.875rem !important;
                margin-top: 0 !important;
            }
        }

        .btn {
            font-weight: 400 !important;
            padding: 6px 10px !important;
            min-width: 250px;
            border-radius: 45px !important;
        }
    }
}