@import "../Scss/mixins";

.ApplicationFilter {
  padding: 100px 40px 0 40px;
  width: 100%;
  transition: all 0.3s ease-in-out;

  @include respond-tab-land {
    padding: 50px 50px 0;
  }

  @include respond-sm-phone {
    padding: 100px 30px 0;
  }

  .whiteBackground {
    .GreyContainer {
      background-color: #ffffff;

      &:hover {
        background-color: var(--color-grey);

        a {
          color: #ffffff;
        }
      }
    }
  }

  .MuiGrid-root.MuiGrid-item {
    flex-basis: 20%;
    max-width: 20%;
    display: flex;
    display: -ms-flexbox;

    @include respond-lg-screen {
      flex-basis: 20%;
      max-width: 20%;
    }

    @include respond-tab-port {
      flex-basis: 33%;
      max-width: 33%;
    }

    @include respond-sm-phone {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}