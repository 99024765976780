@import "../Scss/mixins.scss";
@import "../Scss/variables.scss";

/************* discover-saint-gobain Section **************/
.discover-saint-gobain {
  position: relative;
  padding: 50px 40px 20px 40px;
  color: var(--color-white);
  margin-top: 50px;
  background: linear-gradient(114.27deg, var(--color-primary), #642355);

  .header {
    margin-bottom: 5px !important;
    color: #fff;
    font-size: 2rem !important;
  }

  .subheader {
    font-size: 3.3rem !important;
    margin-bottom: 25px !important;
  }

  .discover-text {
    padding-right: 10px;

    @media screen and (max-width: 900px) {
      padding-right: 0;
    }
  }

  @include respond-laptop {
    min-height: 450px;
  }

  @media screen and (max-width: 1199px) {
    margin-top: 30px;
  }

  @include respond-tab-land {
    min-height: 505px;
  }

  @include respond-tab-port {
    height: auto;
    padding: 50px 30px 0 30px;
    min-height: auto;
  }

  p {
    font-weight: normal;
    margin-top: 0;
    text-align: left;
    margin-bottom: 15px;
    line-height: 20px;

    @include respond-laptop {
      font-size: 20px;
    }

    @include respond-md-desk {
      margin-bottom: 12px;
      line-height: 22px;
    }

    @include respond-tab-land {
      font-size: 18px;
      margin-bottom: 20px;
    }

    @include respond-md-phone {

      &:first-child,
      &:nth-child(2) {
        span {
          font-size: 30px !important;
        }
      }
    }

    span {
      line-height: 1;
    }
  }

  button {
    padding: 12px 20px !important;
    background-color: var(--color-primary);
    min-width: 255px;
  }

  .img-wrapper {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: var(--border-radius-45);
    max-height: 552px;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.388);
    position: absolute;
    bottom: -50px;
    max-width: 520px;

    @include respond-tab-land {
      max-height: 392px;
    }

    @media screen and (max-width: 1367px) {
      bottom: -100px;
    }

    @include respond-tab-port {
      max-height: 442px;
      bottom: -40px;
      max-width: 380px;
    }

    @include respond-sm-phone {
      bottom: -50px;
    }

    @media screen and (max-width: 900px) {
      position: relative;
    }
  }

  .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .big-size {
    min-width: 231px;
    font-size: 1rem !important;
    padding: 8px 10px !important;
  }

  .banner-img {
    position: relative;

    .btn-absolute-browse {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0px;
      border: 0px;
      margin: 0px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: var(--color-primary);
      bottom: -60px;
      font-family: $primary-font;
      left: 50%;
      position: absolute;
      right: auto;
      -webkit-transform: translate(-45%, 20%);
      transform: translate(-45%, 20%);
      padding: 7px 20px !important;
      min-width: 230px;
      font-size: 1.5rem;
      line-height: 1.25;
      color: #fff;
      border-radius: var(--border-radius-45);
      justify-content: center;

      &.big-size {
        @include respond-tab-port {
          min-width: 160px !important;
        }
      }

      &:hover {
        text-decoration: none;
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
          rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
      }

      &:active {
        box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
          rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
      }

      @media screen and (max-width: 1367px) {
        bottom: -115px;
      }

      @include respond-md-desk {
        margin-top: 0 !important;
      }

      @include respond-tab-land {
        margin-top: 0;
      }

      @include respond-tab-port {
        bottom: -55px;
      }

      @include respond-sm-phone {
        bottom: -50px;
        transform: translate(-45%, 50%);
      }
    }
  }

  a {
    &.btn.btn-create-account {
      padding: 12px 20px !important;
      min-width: 255px;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      line-height: 1.25;
      border-radius: var(--border-radius-45);
      font-weight: normal;
      text-transform: none;
      box-shadow: none;

      &:active {
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
      }

      &:hover {
        -webkit-text-decoration: none;
        text-decoration: none;
        background-color: var(--color-secondary) !important;
        color: var(--color-white) !important;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
          0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
      }
    }
  }

  .MuiButton-root.MuiButton-contained {
    @include respond-tab-land {
      margin-top: 20px;
    }

    @include respond-tab-port {
      padding: 15px;
    }
  }

  .MuiGrid-root.MuiGrid-container {
    @include respond-tab-port {
      margin: 0;
      width: 100%;
    }
  }

  .btn.btn-big.btn-discover {
    @include respond-tab-port {
      padding: 16px 10px;
      font-size: 18px;
    }
  }

  .btn-create-account.btn.btn-big {
    font-family: $primary-font;
    background-color: var(--color-white) !important;
    color: var(--color-primary) !important;
    margin-bottom: 20px;
    margin-top: 0 !important;
    float: left;
    font-size: 18px;

    &:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
      background-color: var(--color-secondary) !important;
      color: var(--color-white) !important;
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }

    @media screen and (max-width: 900px) {
      margin-bottom: 0;
      margin-top: 20px !important;
    }
  }
}

.skeleton-banner-background {
  height: 600px !important;
  position: relative;
  padding: 50px 40px 20px 40px;
  color: var(--color-white);
  margin-top: -70px !important;
  left: 0;
  width: 100%;
}

.skeleton-banner-image {
  height: 500px !important;
  width: 518px;
  position: absolute;
  right: 50px;
  top: 120px;
  border: 1px px solid #ccc;
  background-color: #ccc !important;
  border-radius: var(--border-radius-45);
  max-height: 552px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgb(0 0 0 / 39%);
}

.skeleton-banner-image + .ApplicationFilter {
  margin-top: -100px;
}