.select-grey {
    margin: 0 !important;

    .MuiSelect-select.MuiSelect-outlined {
        padding: 0 !important;
        margin: 0 !important;
        color: var(--color-grey);
    }

    svg.MuiSvgIcon-root {
        color: var(--color-grey);
    }
}

ul li.MuiMenuItem-root.MuiMenuItem-gutters {
    display: block;
    width: 100%;

    span {
        float: right;
    }

    svg {
        display: inline-block;
        float: left;
        margin-right: 10px;
    }

    p {
        padding-left: 15px;
    }
}