@import "../Scss/mixins";

.contact-us {
  padding: 30px 40px !important;
  padding-bottom: 5rem !important;

  input::-webkit-input-placeholder {
    color: var(--color-grey) !important;
    font-weight: 300;
  }

  h2 {
    margin-bottom: 35px;
  }

  svg[data-testid="ArrowDropDownCircleIcon"] {
    padding-right: 7px;
  }

  .MuiSelect-select {
    padding: 7.5px 14px;

    p {
      margin: 0;
    }
  }

  .contactus-container {
    width: 70%;
    margin: 0 auto;

    .MuiInputLabel-root.MuiInputLabel-formControl {
      left: -10px;
    }

    .MuiFormHelperText-root.Mui-error {
      margin-left: 0;
    }
  }
}

.center div {
  margin: auto;
}