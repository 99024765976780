.float-label {
    position: relative;

    .label {
        font-size: 12px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 12px;
        top: 12px;
        transition: 0.2s ease all;
    }

    .label-float {
        top: -10px;
        font-size: 10px;
        background-color: var(--color-white);
    }
}

.search-container {
    .ant-select-selector {
        height: auto !important;
        padding: 0 !important;
        border: 0 none !important;

        .ant-select-selection-search {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            color: rgba(0, 0, 0, 0.87);
            box-sizing: border-box;
            cursor: text;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            width: 100%;
            position: relative !important;
            font-family: "Ubuntu", sans-serif !important;
            inset-inline-start: 0;
            inset-inline-end: 0;

            input {
                color: var(--color-primary) !important;
                padding: 7px 14px !important;
                flex-grow: 1;
                height: 39px !important;
                text-overflow: ellipsis;
                opacity: 1;
                font-family: "Ubuntu", sans-serif;
                letter-spacing: inherit;
                background: none !important;
                margin: 0px !important;
                -webkit-tap-highlight-color: transparent;
                display: block;
                min-width: 0px;
                width: 100% !important;
                animation-name: mui-auto-fill-cancel;
                animation-duration: 10ms;
                border-radius: 26px;
                border: 1px solid #00448a !important;
            }
        }
    }

    &.ant-select-focused {
        .ant-select-selector {
            box-shadow: none !important;
        }
    }
}

.ant-select-item.ant-select-item-option {
    cursor: pointer;
}

.ant-select-selection-placeholder {
    font-size: 1rem;
    font-family: "Ubuntu", sans-serif;
    color: rgba(0, 0, 0, 0.6);
}

[style="position: absolute; top: 0px; left: 0px; width: 100%;"] {
    z-index: 1060;
}

.ant-select-dropdown {
    margin: 0;
    color: #000000d9;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: var(--color-white);
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    pointer-events: all !important;
}

.search-container.not {
    .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
        display: none;
    }
}

.search-container {
    .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
        display: block;
    }
}

.ant-select-item.ant-select-item-option {
    min-height: auto;
    padding: 0;

    .ant-select-item-option-content {
        overflow: hidden;
        white-space: break-spaces;
        text-overflow: ellipsis;
        word-break: break-all;
    }
}