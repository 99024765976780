@import "./Scss/variables.scss";
@import "./Scss/mixins.scss";

a .MuiAvatar-root {
  background-color: rgb(189, 189, 189);
  margin-left: 10px;
  width: 30px;
  height: 30px;
}

.product-container.embed-product-container .product-nav-btn button.poc-profile-button .MuiAvatar-root {
  background-color: var(--color-primary);
  width: 30px;
  height: 30px;

  svg {
    font-size: 1.3rem !important;
    margin-left: 0;
  }
}

a,
a p {
  align-items: center;
  display: inline-flex;
  color: var(--color-primary);
}

a p {
  @include respond-md-phone {
    display: none;
  }
}

.login {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-primary) !important;
}

.profile-dropdown {
  ul {
    li {
      font-family: $primary-font !important;

      a {
        font-family: $primary-font !important;
      }

      svg {
        color: var(--color-primary);
      }
    }

    .MuiDivider-root {
      margin-top: 0;
      margin-bottom: 0px;
    }
  }
}