@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import 'Utils/Scss/variables';
@import 'Utils/Scss/mixins';
@import 'Utils/Scss/utilities';
@import 'Utils/Scss/accordion';
@import 'Utils/Scss/checkbox';
@import 'Utils/Scss/chip';
@import 'Utils/Scss/filter';
@import 'Utils/Scss/form';
@import 'Utils/Scss/dialog';
@import 'Utils/Scss/link';
@import 'Utils/Scss/table';
@import 'Utils/Scss/pagination';
@import 'Utils/Scss/select';
@import 'Utils/Scss/header';
@import 'Utils/Scss/footer';

html {
  height: 100%;
}

body,
#root,
.App {
  position: relative;
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}