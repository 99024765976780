table {
    border-collapse: collapse;
    border-radius: 4px;
    border: 1px solid #707070;
    width: 100%;

    svg {
        font-size: 1.3rem;
    }

    th,
    td {
        border: 1px solid var(--color-grey);
        border-top: 0;
        padding: 6px 10px !important;
        font-family: $primary-font !important;

        &:first-child {
            border-left: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }

    tr {
        &:last-child {
            td {
                border-bottom: 0 none;
            }
        }

        th {
            background-color: var(--color-primary);
            color: var(--color-white);
            font-size: 16px;
            text-align: left;
            font-weight: normal;
        }

        td {
            font-size: 14px;

            svg {
                float: left;
                margin-right: 10px;
            }
        }
    }
}

.MuiTableContainer-root {
    tr {

        th,
        td {
            background-color: #ffffff;
            border-bottom: 1px solid var(--color-grey);
            font-size: 12px;
            padding: 6px 10px;
            word-break: break-all;
        }
    }
}