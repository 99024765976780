@import "./Scss/mixins";

.product-slider {
  .main-slider {
    border: 1px solid var(--color-grey);
    background: #fff;
    padding: 20px;
    outline: none !important;

    &:hover {
      border-color: var(--color-primary);
    }

    .slick-current {
      outline: none !important;
      display: flex !important;
      justify-content: center;
    }

    .slick-slide img {
      margin: 0 auto;
      outline: none !important;

      @include respond-tab-port {
        object-fit: cover;
        object-position: bottom;
      }
    }

    .slick-prev {
      left: 5px;
    }

    .slick-next {
      right: 0px;
    }

    .slick-arrow {
      color: var(--color-grey);
      font-size: 28px;
      z-index: 1;
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      display: flex;
      margin: auto;
    }
  }

  .nav-slider {
    margin-top: 15px;

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      margin-right: 8px;
      height: inherit !important;
      display: flex !important;
      justify-content: center;
      border: 2px solid #ccc;
      align-items: center;
      background-color: #fff;

      img {
        max-height: 80px;
        width: 120px;
      }

      &.slick-current {
        border: 2px solid var(--color-primary);
      }

      &.slick-active div div {
        outline: none;
        vertical-align: middle;
      }
    }
  }
}