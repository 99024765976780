:root {
    --color-primary: #00448A;
    --color-secondary: #219CDC;
    --color-white: #FFFFFF;
    --color-danger: #CE1431;
    --color-primary-30: rgba(0, 68, 138, .39);
    --color-primary-10: rgba(0, 68, 138, .1);
    --color-secondary-70: rgba(33, 156, 220, .7);
    --color-black: rgb(0, 0, 0);
    --border-radius-45: 45px;
    --border-radius-36: 36px;
    --border-radius-6: 6px;
    --color-grey: #707070;
    --color-lightgrey: #adadad;
    --color-grey-1: #F6F6F6;
    --font-size-16: 16px;
    --font-size-18: 18px;
    --font-size-20: 20px;
    --font-size-22: 22px;
    --font-size-24: 24px;
}

$primary-font : 'Ubuntu',
sans-serif;