@import "../Scss/mixins.scss";
@import "../Scss/variables.scss";

.browse-container {
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  justify-content: space-between;
  min-height:calc(100vh - 43px);

  @include respond-tab-land {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .brand-product-container {
    p {
      margin-bottom: 0;
      font-size: 0.875rem;
    }

    .browse-skeleton {
      padding: 0 16px;
    }
  }
}

.brand-skeleton {
  margin-bottom: 25px;

  .MuiSkeleton-root.MuiSkeleton-rectangular {
    width: 12%;
    height: 34px;
    border-radius: 10px;
    display: inline-flex;
    margin-right: 0.5%;
  }
}

aside {
  width: 20%;
  background-color: #fff;

  @include respond-tab-land {
    width: 100%;
    padding: 20px;
    background-color: #f3f3f3;
  }
}

.brand-product-container {
  margin-left: 21%;
  width: 100%;
  padding: 50px 0;

  @include respond-tab-land {
    width: 100%;
    margin-left: 0;

    .MuiListItem-root {
      padding-left: 0;
    }
  }
}

.browseBrands {
  padding: 0;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 1rem;

  @include respond-tab-land {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond-sm-phone {
    grid-template-columns: repeat(2, 1fr);
  }

  li {
    width: auto !important;
    border: 2px solid transparent !important;

    &.Active {
      .chip-brand {
        background-color: #0000;
        color: #f0f8ff;
        border: 0 none;
      }
    }

    &:hover {
      border-color: var(--color-primary) !important;
    }

    .chip-brand {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;
      color: var(--color-grey);
      padding: 4px 10px;
      background-color: #0000;
      border: 0 none;
      margin-right: 0;
      white-space: nowrap;
      overflow: hidden;

      &.all-brand-text {
        text-transform: uppercase;
      }
    }

    &.brand-chip-button {
      justify-content: center;

      .filterDropdown {
        width: 100% !important;

        .MuiInputBase-formControl.chip-brand {
          .MuiSelect-select.MuiSelect-outlined {
            justify-content: center;
            padding: 0 !important;
            height: 34.3px;
            width: 100%;
            align-items: center;
            padding-right: 30px !important;
            padding-left: 14px !important;

            @include respond-lg-desk {
              justify-content: left;
              padding-left: 5px !important;
            }
          }

          .sort-icon {
            right: 20px;
            padding-left: 5px;

            @include respond-lg-screen {
              right: 5px;
            }

            @include respond-laptop {
              right: 0;
            }

            @include respond-lg-desk {
              right: 5px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}

.browse-container {
  background-color: rgba(0, 0, 0, 0.06);

  .filter-container {
    .page-filter {
      padding: 20px 30px 10px 30px;
    }
  }

  .filterDropdown {
    .MuiInputBase-formControl {
      font-size: 0.875rem;
      margin-right: -10px;
    }

    .sort-icon {
      position: absolute;
      right: 0px;
      top: calc(50% - 0.5em);
      pointer-events: none;
    }

    .MuiSelect-select {
      padding-right: 25px !important;
    }
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  ul {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 20px;

    .all-brand-btn {
      border: 1px solid #ccc;
      margin: 10px;
      padding: 3px 10px;
      width: auto !important;
      border-radius: 15px;
      font-size: 14px;
      font-family: "Ubuntu";
      text-align: center;
      word-break: break-all;
      white-space: normal;

      &:hover {
        transform: none !important;
        background-color: var(--color-secondary) !important;
        border-color: var(--color-secondary);
        color: var(--color-white);
      }

      &:active {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: var(--color-white);
      }

      &.Active.Mui-selected {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: var(--color-white);
      }
    }

    .menu-item {
      justify-content: center;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.3);
        cursor: pointer;
      }
    }

    .MuiButtonBase-root.MuiMenuItem-root:hover {
      background-color: #fff;
    }
  }
}