@import "../Scss/mixins.scss";
@import "../Scss/variables";

/**************** Choose Products ****************/
.choose-product-brand {
  padding: 50px 40px 0 40px;
  display: flex;
  flex-direction: column;
  @include full-width;
  float: none;

  @include respond-tab-land {
    padding: 50px;
  }
}

.custom-grid.p-100 {
  @include respond-tab-port {
    padding: 50px;
  }
}

.chip-container {
  @include full-width;
  margin-top: -10px !important;

  ul {
    @include full-width;
    padding: 0 16px;

    li {
      float: left;
      display: inline-flex;
      width: 16.66%;

      @include respond-tab-land {
        width: 25%;
      }

      @include respond-sm-phone {
        width: 50%;
      }

      @include respond-xs-phone {
        width: 100%;
      }
    }
  }

  .MuiGrid-root {
    justify-content: center;
    margin-top: 0;
  }

  .more-btn {
    color: #8b8b8b;
    display: flex;
    display: -ms-flexbox;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    justify-content: center;
    font-family: $primary-font !important;
    text-transform: capitalize;
  }
}

.MuiListItemButton-root {
  &.chip-brand {
    display: flex;
    display: -ms-flexbox;
    text-align: center;
    background-color: var(--color-white);
    height: 96px;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in;
    float: left;
    margin: 0;
    overflow: hidden;
    padding: 0;
    border: 2px solid #fff;
    margin-right: 10px;

    @include respond-tab-port {
      padding: 5px 12px;
      width: 100px;
      height: 75px;
    }

    img {
      max-width: 70%;
      transition: all 0.4s ease-in-out;
      object-fit: cover;
      max-height: 70px;
      height: 100%;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .MuiListItemText-root {
    margin: 0;
  }

  .MuiTypography-root {
    line-height: 1;
  }
}

.browseBrands {
  li {
    color: var(--color-grey);
    height: auto;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;

    &.Active {
      background-color: var(--color-primary);
      color: var(--color-white);

      .chip-brand {
        border: 0 none !important;
      }
    }
  }
}