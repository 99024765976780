.without-border {
    .MuiAccordion-root {
        border: 0 none;

        .MuiAccordionSummary-root {
            background-color: transparent;

            .MuiAccordionSummary-content {
                margin: 0 0 0 8px;
            }
        }
    }
}

.filter-container {
    .MuiAccordionDetails-root {
        padding: 8px 16px 0 15px;

        ul {
            max-width: 100%;
            padding-top: 0;

            li {
                div {
                    padding-left: 0;
                }
            }
        }
    }
}

.MuiAccordionSummary-content {
    p {
        margin-bottom: 0 !important;
    }
}