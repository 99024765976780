[aria-labelledby="filter-dropdown-multiple-selection"] {
  margin: 0 !important;

  .filter-dropdown-list {
    padding: 5px 0 0 0;
    margin-bottom: 0 !important;

    &:first-child {
      padding-top: 0;
    }
  }
}

.filter-list-element-poc {
  display: grid;
  padding-top: 0;
  grid-template-columns: repeat(6, 16%);
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;

  .filter-element.filter-element-poc {
    display: inline-block;
    background-color: var(--color-primary);
    color: var(--color-white);

    h4 {
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 5px;
      padding-left: 5px;
    }

    &:last-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:first-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    p {
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.87);
      text-align: left;
    }

    .MuiAccordionSummary-contentGutters {
      p {
        color: var(--color-white);
      }
    }

    .filter-content {
      padding: 0;
    }

    &.global-filter {
      background-color: var(--color-secondary);
    }

    ul {
      padding: 0;

      .filter-content-item {
        padding: 10px;
        display: flex;
        justify-content: space-between;
      }
    }

    &.Mui-expanded {
      margin: 0 0 0 0 !important;
    }

    .MuiCollapse-vertical {
      position: absolute;
      z-index: 999;
      width: 100%;
      background-color: rgb(255, 255, 255);
      border: 1px solid #ccc;
    }

    .MuiAccordionSummary-gutters {
      min-height: 40px;

      [data-testid="ListIcon"] {
        margin-right: 10px;
      }

      .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
        margin: 0 !important;
        align-items: center;
      }

      .MuiAccordionSummary-expandIconWrapper {
        margin-left: 10px;

        svg {
          color: var(--color-white);
          width: 20px;
          height: 20px;
          color: var(--color-white);
        }
      }
    }
  }
}