@import "mixins";

/******************** Footer ****************************/
.footer-section {
  align-items: center;
  justify-content: center;
  display: flex;
  display: -ms-flexbox;
  padding: 50px 100px;
  flex-direction: column;

  @include respond-md-desk {
    padding: 30px;
  }

  img + a {
    @include respond-md-desk {
      margin-top: 30px;
    }
  }

  img {
    margin: 0;
  }

  @include respond-md-phone {
    flex-direction: column;

    .btn.btn-big.btn-discover {
      font-size: 1.125rem;
      padding: 16px 10px;
    }
  }
}

footer {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  background-color: var(--color-primary);
  align-items: center;
  color: var(--color-white);
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 6px 1rem;
  z-index: 2;

  svg {
    font-size: 1.2rem;
  }

  @include respond-md-desk {
    flex-direction: column;

    div {
      margin-bottom: 20px;
    }

    .footer-nav {
      text-align: center;
      display: flex;
      display: -ms-flexbox;
      margin-bottom: 20px;
      line-height: .9rem;

      @include respond-sm-phone {
        flex-direction: column;
      }

      a {
        font-size: 14px;

        @include respond-sm-phone {
          justify-content: center;
          padding: 0 !important;
        }
      }
    }
  }

  .footer-logo-copyrights {
    align-items: center;
    display: flex;

    @include respond-md-desk {
      justify-content: center;
      display: flex;
      margin-bottom: 20px;
    }

    .copyright {
      color: var(--color-white);
      font-weight: 400;
      line-height: 0.9rem;
      font-size: 0.65rem;
      display: inline-flex;
      padding: 0 10px 0 20px;
      margin: 0 !important;
      align-items: center;
      text-align: left;
      padding-top: 5px;

      @include respond-md-desk {
        text-align: center;
        width: 100%;
      }
    }
  }

  .footer-nav {
    a {
      margin: 0;
      color: var(--color-white);
      -webkit-text-decoration: none;
      text-decoration: none !important;
      padding: 0 10px 0 5px;
      font-size: 0.65rem;
      cursor: pointer;
      font-weight: normal;

      @include respond-xs-phone {
        padding: 0 5px;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .social-link-container a {
    color: var(--color-primary);
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: var(--color-white);
    padding: 0 10px;
    margin: 0 10px 0 0;
    width: 25px;
    height: 25px;
    border: 1px solid var(--color-white);
    border-radius: 50%;
    line-height: 25px;
    float: left;
    display: flex;
    display: -ms-flexbox;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;

    &:hover {
      box-shadow: inset 0px 0px 0px 2px #1565c0;
      color: #1565c0;
    }

    @include respond-md-desk {
      margin-bottom: 20px;
    }
  }
}

.legal-privacy-cookie-container {
  padding: 60px 40px !important;
  padding-bottom: 5rem !important;
}

.sitemap-container {
  padding: 90px 40px !important
}