@import "../Scss/variables.scss";
@import "../Scss/mixins.scss";

/******************* Homepage Browse Product ***************/
.homepage-browse-products {
  padding: 50px 40px 0px 40px;
  @include full-width;
  float: none;

  @include respond-tab-land {
    padding: 50px;
  }

  .custom-grid-item {
    min-width: 16.67%;

    @include respond-tab-land {
      width: 100%;
    }

    &:last-child {
      @include respond-tab-land {
        width: 100%;
        text-align: center;
      }
    }

    @include respond-lg-screen {
      justify-content: start;
    }
  }

  .btn.btn-default.btn-browse-more {
    padding: 12px 8px !important;
    border-radius: 20px !important;
    font-size: 16px !important;
    cursor: pointer;
    justify-content: center;
    display: flex;

    @include respond-tab-land {
      font-size: 15px !important;
    }

    p {
      display: inline-flex;
      margin: 0;
      color: var(--color-white);
    }
  }

  .custom-grid {
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .homepage-product-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 1rem;

    @include respond-md-desk {
      grid-template-columns: repeat(5, 1fr);
    }

    @include respond-tab-land {
      grid-template-columns: repeat(3, 1fr);
    }

    @include respond-sm-phone {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond-xs-phone {
      grid-template-columns: repeat(1, 1fr);
    }

    .MuiListItem-root.MuiListItem-gutters {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .homepage-browse-btn-grid {
    display: grid;
    align-content: center;
  }
}

.custom-grid {
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
}

.custom-grid-item {
  margin: auto;
}

.product-card {
  .MuiCardContent-root {
    p {
      font-weight: 500;
      text-transform: uppercase;
      color: var(--color-secondary);
      font-family: $primary-font !important;
      display: flex;
      justify-content: center;
      margin-bottom: 0 !important;
      text-align: center;
      font-size: 0.8rem;
    }
  }

  .MuiCardContent-root {
    text-align: center;
    color: var(--color-primary);
    font-weight: bold;
    font-size: 0.9375rem;
    font-family: $primary-font !important;
    // height: 34px;
    width: 65%;
    margin: 0 auto;

    p {
      font-family: $primary-font !important;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

nav.MuiPagination-root ul.MuiPagination-ul li .MuiButtonBase-root.MuiPaginationItem-root {
  font-size: 14px;
  height: auto;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  padding: 0;
}