@import '../Scss/mixins.scss';

/********************** Header ****************************/
header.App-header {
  min-height: 80px;
  border-bottom: 2px solid #bbb;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  @include respond-tab-land {
    flex-direction: column !important;
  }

  @include respond-tab-port {
    position: relative;
    z-index: 2;
  }

  @include respond-xs-phone {
    justify-content: normal;
  }

  .logo-container {
    width: 25%;

    @include respond-laptop {
      width: 35%;
    }

    @include respond-md-desk {
      width: 20%;
    }

    @include respond-tab-land {
      width: 100%;
      display: block;
      position: fixed;
      background-color: #fff;
      z-index: 9;
      top: 0;
    }

    @include respond-md-phone {
      display: block;
      position: fixed;
      background-color: #fff;
      z-index: 9;
    }

    .ribbon-box {
      background-color: var(--color-primary);
      color: var(--color-white);
      top: 71px;
      right: auto;
      left: 0;
      position: absolute;
      padding: 0 30px 0 0;
      z-index: 2;
      bottom: 0;
      height: 24px;
      font-size: 16px;
      font-weight: normal;
      width: 25%;
      line-height: 24px;
      text-align: right;
      clip-path: polygon(0 0, 96% 0, 98% 100%, 0% 100%);

      @include respond-laptop {
        width: 28%;
      }

      @include respond-md-desk {
        width: 40%;
      }

      @include respond-tab-land {
        width: 100%;
        clip-path: none;
        text-align: center;
        line-height: 34px;
        height: 34px;
        position: relative;
        top: 0;
        margin-top: 71px;
      }

      @include respond-xs-phone {
        text-align: right;
      }
    }

    .display-country {
      margin-left: 220px;
      font-size: 30px;
      text-transform: uppercase;
      z-index: 5;
      color: rgba(55, 111, 167, 0.25);
      font-weight: 600;
    }
  }

  .search-market-container {
    align-items: center;
    display: flex;
    display: -ms-flexbox;
    width: 45%;

    @include respond-laptop {
      width: 40%;
    }

    @include respond-md-desk {
      width: 40%;
    }

    @include respond-tab-land {
      width: 100%;
      justify-content: end;
      margin-top: 10px;
      z-index: 11;
      position: fixed;
      top: 0;
    }

    @include respond-md-phone {
      width: 95%;
      margin-top: 130px;
      justify-content: space-around;
      z-index: 0;
      position: relative;
      top: auto;
    }

    @include respond-xs-phone {
      justify-content: center;
      width: 95%;
      margin-top: 110px;
      flex-direction: column;
    }

    .search-container {
      width: 70%;
      display: inline-flex;
      border: 1px solid var(--color-primary);
      margin-right: 20px;
      align-items: center;
      border-radius: 26PX;

      @include respond-tab-land {
        margin-right: 0px;
        width: 48%;
      }

      @include respond-md-phone {
        width: 70%;
      }

      @include respond-xs-phone {
        width: 98%;
      }

      input {
        padding: 14px;
        color: var(--color-primary);

        @include respond-xs-phone {
          padding: 8px 12px;
          color: var(--color-primary);
          font-size: 16px;
        }
      }

      button {
        color: var(--color-primary);
      }

      .MuiSvgIcon-root {
        width: 1.11em;
        height: 1.11em;
      }
    }
  }

  .without-outline-select {
    flex-direction: row !important;

    fieldset {
      border: 0 none;
    }

    .MuiSelect-select {
      align-items: center;
      display: inline-flex;
      font-weight: 500;
      color: var(--color-primary);
      padding: 6px !important;
      text-transform: uppercase;
      font-size: 16px;
      font-family: $primary-font;
    }

    .MuiSvgIcon-root {
      font-size: 1.7rem;
      color: rgb(0, 68, 138);
      right: 0;
      padding-right: 8px !important;

      &.language-icon {
        padding-right: 0 !important
      }
    }

    p {
      display: inline-flex;
      box-sizing: border-box;
      color: var(--color-primary);
      text-transform: uppercase;
      align-items: center;
    }

    li {
      color: #999;
      font-weight: 700;
      border-top: 1px solid #c5c5c5;
      background-color: #fff;
      height: 2.5em;
      line-height: 2.5em;
      padding-left: 2em;
    }
  }

  .login-lang-container {
    flex-direction: row;
    color: var(--color-primary);
    width: 30%;
    justify-content: end;
    display: flex;
    display: -ms-flexbox;
    align-items: center;

    @include respond-md-desk {
      width: 20%;
    }

    @include respond-tab-land {
      width: 100%;
      margin-top: 103px;
    }

    @include respond-md-phone {
      margin-top: 0px;
      justify-content: space-between;
      padding: 0 15px;
    }

    @include respond-xs-phone {
      justify-content: center;
    }

    a,
    a p {
      align-items: center;
      display: inline-flex;
      color: var(--color-primary);

      @include respond-md-phone {
        font-size: 13px;
      }
    }

    .mydownloads-container {
      margin-right: 25px;
      align-items: center;
      display: flex;
      display: -ms-flexbox;

      @include respond-tab-land {
        margin-top: 0;
        width: auto;
        margin-right: 20px;
      }

      @include respond-xs-phone {
        margin-right: 5px;
        min-width: auto;
        justify-content: start;
      }

      a,
      a p {
        align-items: center;
        display: inline-flex;
        color: var(--color-primary);

        @include respond-md-phone {
          font-size: 13px;
        }
      }

      .MuiSvgIcon-root {
        margin-right: 10px;

        @include respond-tab-land {
          margin-right: 0px;
        }
      }
    }

    p {
      display: none;
    }

    .MuiAvatar-root {
      background-color: var(--color-primary);
      margin-left: 10px;
    }

    & > div {
      justify-content: end;
      min-width: 80px;

      @include respond-tab-land {
        min-width: 70px;
      }

      @include respond-md-phone {
        min-width: auto;
      }
    }
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .login-lang-container > div {
    justify-content: end;
    min-width: 80px;

    @include respond-xs-phone {
      min-width: auto;
    }
  }

  .market-select > div > span {
    display: none;
  }
}