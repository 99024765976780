@import "./variables.scss";
@import "./mixins.scss";

:after,
:before,
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  font-size: 14px;
  font-family: $primary-font !important;
}

.MuiTypography-root,
.MuiFormControl-root,
.MuiOutlinedInput-root {
  font-family: $primary-font !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-100 {
  padding: 100px;
}

.p-0 {
  padding: 0 !important;
}

.p-l-50 {
  padding-left: 50px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-50 {
  padding-right: 50px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-20 {
  padding-left: 20px;
}

.pos-rel {
  position: relative;
}

.rotate-270 {
  transform: rotate(270deg);
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.full-width {
  @include full-width;
}

.border-radius-13 {
  border-radius: 13px !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.d-flex {
  display: flex;
  display: -ms-flexbox;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: end;
  display: flex;
  display: -ms-flexbox;
}

.align-items-center {
  align-items: center;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-s-italic {
  font-style: italic;
}

.font-primary {
  font-family: $primary-font !important;
}

.font-size-16 {
  font-size: var(--font-size-16) !important;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 1.25rem;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-38 {
  font-size: 2.7rem !important;
}

.font-size-10 {
  font-size: 0.875rem !important;
}

.blue-box {
  border: 1px solid var(--color-primary) !important;
  text-align: center;
  padding: 15px;
}

.img-responsive {
  max-height: 100%;
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

input::-webkit-input-placeholder {
  color: var(--color-primary) !important;
  font-weight: 300;
}

.css-yz9k0d-MuiInputBase-input::-webkit-input-placeholder {
  opacity: 0.72;
  font-size: 1rem;
}

.App {
  font-family: $primary-font;
  background-color: var(--color-white);
  font-size: 14px;

  .App-header {
    background-color: var(--color-white);
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: var(--color-white);
    flex-direction: row;
  }
}

.MuiButton-root.btn {
  border-radius: var(--border-radius-45);
  font-weight: normal;
  font-size: 1rem;
  text-transform: none;
  box-shadow: none;
  padding: 8px 25px;
  line-height: 1.25;

  &.btn-primary,
  &.btn-big {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  &.Mui-disabled {
    cursor: not-allowed;
    background-color: rgb(0 68 138 / 20%);
    pointer-events: all;
  }
}

.btn.btn-big.btn-discover {
  padding: 12px 30px;
  top: 10px;
}

h1,
h2.heading-two {
  font-size: 2.25rem !important;
  color: var(--color-secondary);
  text-transform: uppercase;
  font-weight: 500 !important;
  margin: 1rem 0 !important;

  @include respond-tab-port {
    font-size: 30px;
  }
}

h1.section-heading,
h2.section-heading {
  font-size: 1.4rem !important;
  color: var(--color-grey);
  font-weight: 500 !important;
  margin-top: 0 !important;
  text-align: left;
  margin-bottom: 1.875rem !important;
  text-transform: capitalize;
  line-height: 1.2;
  letter-spacing: -0.00833em;

  @include respond-tab-port {
    font-size: 1.375rem;
  }
}

h3,
.MuiTypography-h3 {
  font-family: $primary-font;
  font-size: 1.3em !important;
  line-height: 28px;
  text-transform: uppercase;
  display: block !important;
}

.MuiTypography-h3,
h1.MuiTypography-body1 {
  font-weight: 500 !important;
  margin: 1rem 0 !important;
}

section {
  background-color: var(--color-white);
  font-family: $primary-font;
}

.btn-primary {
  padding: 12px 25px !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  border-radius: 6px !important;
  font-family: $primary-font !important;
}

.btn-default {
  background-color: var(--color-grey) !important;
  color: var(--color-white) !important;
}

.btn-primary.btn-normal {
  padding: 5px 10px !important;
  font-weight: 400 !important;
  font-size: 14px !important;

  svg {
    font-size: 1rem;
    margin-left: 5px;
  }
}

.btn-primary.btn-small,
.btn-default {
  padding: 5px 7px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-radius: 20px !important;

  @include respond-xs-phone {
    padding: 7px 10px !important;
    font-size: 14px !important;
  }
}

.btn-primary.btn-medium {
  padding: 16px !important;
  font-weight: 500 !important;
  font-size: 18px !important;

  @include respond-md-phone {
    font-size: 14px !important;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.text-center {
  text-align: center !important;
}

.text-danger {
  color: var(--color-danger);
}

.min-h-340 {
  min-height: 340px;
}

.width-5 {
  width: 5%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-35 {
  width: 35%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 55%;
}

.width-60 {
  width: 60%;
}

.width-65 {
  width: 65%;
}

.width-70 {
  width: 70%;
}

.width-75 {
  width: 75%;
}

.width-80 {
  width: 80%;
}

.width-95 {
  width: 95%;
}

.width-100 {
  width: 100%;
}

.primary-header {
  font-size: 30px;
  margin: 0 0 10px 0;
  color: var(--color-primary);

  @include respond-md-phone {
    font-size: 22px;
  }
}

.secondary-header {
  font-size: 22px;
  margin: 0 0 24px 0;
  color: var(--color-secondary);
  font-style: italic;

  @include respond-md-desk {
    margin-bottom: 0;
  }

  @include respond-md-phone {
    font-size: 0.8rem;
  }
}

strong {
  font-weight: 700;
  font-family: $primary-font;
}

p,
p.MuiTypography-body1 {
  margin: 0 0 15px 0;
  font-family: $primary-font;
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: justify;
}

ul {
  li {
    position: relative;
    font-size: 0.8rem;
    margin-bottom: 10px;
    -webkit-align-items: start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: inherit !important;
    align-items: start !important;

    a {
      cursor: pointer;
    }

    span.MuiTypography-root {
      font-size: 0.8rem;
    }
  }
}

a {
  color: var(--color-secondary);
  text-decoration: none;
}

.main-container {
  width: 100%;
  padding: 45px 0px;
  margin-top: 0;
  padding-bottom: 0;
  min-height: calc(100vh - 43px);
  position: relative;
  word-break: break-word;

  @include respond-tab-port {
    margin-top: 0;
  }
}

.responsive-table {
  max-width: 100%;
  overflow-x: auto;
  border: 1px solid var(--color-grey);

  table {
    border: 0 none;
  }
}

.bg-grey {
  background-color: var(--color-grey-1);
}

.text-grey {
  color: var(--color-grey);
}

.text-grey-2 {
  color: var(--color-grey-2);
}

.text-primary {
  color: var(--color-primary) !important;
}

.text-secondary {
  color: var(--color-secondary);
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.marginAuto {
  flex-direction: row !important;
  width: 100%;
  justify-content: center;
}

.opacity-5 {
  opacity: 0.5;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  margin: 10px 5px !important;
  font-size: 16px;
}

.btn.btn-primary.btn-popup {
  border-radius: 32px !important;
  font-size: 1rem !important;
  padding: 7px 10px !important;
  min-width: 120px;
  font-weight: 500 !important;
}

.btn.btn-primary.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.btn.btn-primary.bg-danger {
  background-color: var(--color-danger) !important;
}

.color-secondary {
  color: var(--color-secondary) !important;
}

.portal {
  .tabs {
    width: 378px;
    height: 200px;
    position: absolute;
    left: 30px;
    top: -20px;
    z-index: 5;
    border: 2px solid #707070;
    background-color: var(--color-white);
  }
}

.floating-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  border: 1px solid var(--color-primary);
  color: var(--color-white);
  background-color: var(--color-primary);
  width: 32px;
  height: 32px;
  font-size: 20px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  z-index: 3;
  padding: 5px 10px;
  pointer-events: auto;
  cursor: pointer;
  transition: all 0.5s ease-in;

  &.floating-contact {
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    right: 20px;
  }

  .floating-icon {
    height: 20px;
    display: flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    [data-testid="ArrowForwardIosIcon"] {
      transform: rotate(270deg) !important;
    }

    svg {
      font-size: 1.3rem;
    }
  }

  &:hover {
    background-color: #1565c0;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-color: #1565c0;
  }

  &.floating-settings {
    bottom: 120px;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
  }

  &.floating-settings,
  &.floating-contact {
    &:hover {
      width: auto;
      height: auto;
      border-radius: 20px;
      align-items: center;
      display: flex;
      display: -ms-flexbox;
      justify-content: center;

      .floating-text {
        opacity: 1;
        padding-left: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        display: -ms-flexbox;
        font-size: .8rem;
      }
    }
  }

  &.floating-contact {
    bottom: 160px;
    background-color: var(--color-danger);
    border-color: var(--color-danger);
    color: #fff;
  }

  .floating-text {
    opacity: 0;
    display: none;
    font-size: 16px;
    font-weight: normal;
  }
}

.cookiePolicyList {
  .MuiListItemIcon-root {
    min-width: 20px;
    margin-top: 10px;
  }

  .MuiSvgIcon-fontSizeSmall[data-testid="FiberManualRecordIcon"] {
    font-size: 0.4rem;
    color: #000;
  }

  .address div {
    font-size: 0.875rem;
  }
}

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 1rem !important;
  margin-bottom: 0;
  flex-direction: column;

  &.alert-info {
    svg {
      color: var(--color-primary);
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }
}

.denyLocationPopup .MuiDialogContent-root {
  margin: 0;
  padding-top: 0;
  padding-bottom: 30px;

  p {
    padding: 0;
    margin: 0;
  }
}

.selectbox {
  align-items: center;
  display: flex;
  height: 40px;
  min-height: 40px;
  padding: 3px 0 0 0;
}

.text-capitalize {
  text-transform: capitalize !important;
}

ul.sitemap-list {
  list-style: circle;
  color: var(--color-secondary);
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 30px;

  li {
    a {
      &.MuiTypography-root.MuiTypography-inherit.MuiLink-root {
        margin: 0;
        color: var(--color-secondary);
      }
    }
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 100;
}

.smooth-transition {
  transition: all 0.5s ease-in;
}

::placeholder {
  font-size: 0.8rem;
}

.banner-section,
.choose-product-brand,
.homepage-browse-products,
.learn-about-bim,
.footer-content,
.ApplicationFilter {
  @media screen and (min-width:1921px) {
    width: 70% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    float: none;
  }
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media screen and (min-width:1921px) {
    justify-content: space-around;
  }

  @media screen and (max-width:1200px) {
    flex-direction: column;
  }
}

a:-webkit-any-link:focus-visible {
  outline-offset: 0;
  outline: -webkit-focus-ring-color auto 0px;
}

:focus-visible {
  outline-offset: 0;
  outline: -webkit-focus-ring-color auto 0px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

.simplebar-track.simplebar-vertical {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.disabled-delete-btn {
  cursor: not-allowed !important;
  pointer-events: all !important;

  svg {
    color: rgba(0, 68, 138, .2) !important;
  }
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
  background-color: transparent;
}