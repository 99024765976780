@import "../Scss/variables.scss";
@import "../Scss/mixins.scss";

.without-outline-select.lang-form p {
  display: none;
}

.without-outline-select.lang-form {
  @include respond-md-phone {
    align-items: center;
    line-height: 20px;
    height: 20px;
  }

  span {
    display: none;

    @include respond-md-phone {
      display: inline-block;
    }
  }

  span.flag-container {
    display: inline-block
  }

  div {
    padding: 0 !important;
  }

  .MuiSelect-select {
    z-index: 1;

    img {
      float: right;
      position: relative;
      display: block;
    }
  }

  svg {
    right: 16% !important;
    float: right;
    position: relative;
    display: none;
  }

  [id="languageMenu"] {
    width: 25px;
    height: 22px;
    margin-left: 5px;

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
}

.country-flag {
  width: 100%;
  max-height: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.flag-container {
  width: 25px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  height: 20px;
}

ul {
  li.MuiMenuItem-root.MuiMenuItem-gutters {
    display: flex;
    display: -ms-flexbox;
    text-transform: capitalize;
    align-items: center !important;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    p,
    img {
      display: inline-flex;
      margin-bottom: 0;
    }
  }
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: calc(100% - 80px) !important;
}

.marketRegionPopup {
  .without-outline-select.lang-form {
    svg {
      right: 0% !important;
      position: absolute;
    }
  }
}