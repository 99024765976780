@import "../Scss/variables.scss";
@import "../Scss/mixins.scss";

.without-outline-select {
  flex-direction: row !important;
  min-width: fit-content !important;

  fieldset {
    border: 0 none;
  }

  .btn-region {
    font-family: $primary-font !important;
    text-transform: capitalize;
  }

  .MuiSelect-select,
  div {
    align-items: center;
    display: inline-flex;
    font-weight: 500;
    color: var(--color-primary);
    padding: 6px !important;
    font-size: 0.8rem;
  }

  .MuiSvgIcon-root {
    font-size: 1.7rem;
    color: rgb(0, 68, 138);
    right: 0;
    padding-right: 8px !important;
    margin: auto;
  }

  p {
    display: inline-flex;
    box-sizing: border-box;
    color: var(--color-primary);
    text-transform: uppercase;
    align-items: center;
  }

  li {
    color: #999;
    font-weight: 700;
    border-top: 1px solid #c5c5c5;
    background-color: #fff;
    height: 2.5em;
    line-height: 2.5em;
    padding-left: 2em;
  }
}

ul li.MuiMenuItem-root.MuiMenuItem-gutters.region-item {
  display: block;
  height: 2.5em;
}

ul li.MuiMenuItem-root.MuiMenuItem-gutters.region-item span:first-of-type {
  float: left;
}

ul li.MuiMenuItem-root.MuiMenuItem-gutters.region-item span:nth-of-type(2) {
  float: right;
}

.not-selected {
  background-color: var(--color-white) !important;
}