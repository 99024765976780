@import "../Scss/variables.scss";
@import "../Scss/mixins.scss";

/******************* Browse Product ***************/
.cursor-pointer {
  cursor: pointer;
}

.flex-vertical-center {
  justify-content: center;
  display: flex;
  align-items: center !important
}

.MuiPaper-elevation.product-card {
  border: 1px solid rgba(112, 112, 112, 0.3);
  transition: all 0.4s ease-in-out;

  &:hover {
    .img-container {
      img {
        transform: scale(1.15);
      }
    }
  }

  &.active {
    box-shadow: none;
    border: 2px solid var(--color-primary);
  }

  .MuiCardHeader-root {
    padding: 16px 8px;
    align-items: flex-start;
  }

  .MuiCardHeader-content {
    width: 100%;
  }
}

.img-container {
  width: 90%;
  text-align: center;
  cursor: pointer;
  margin: 5px auto 0 auto;
  height: 10em;
  vertical-align: middle;
  line-height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transition: all 0.5s ease;
    margin: 0 auto;
    max-height: 100%;
    height: auto;

    @include respond-xs-phone {
      max-width: 10em;
    }
  }
}

.custom-grid-item {
  width: 100%;

  .MuiLink-root,
  .product-card {
    width: 100%;
  }

  &:last-child {
    @include respond-tab-land {
      width: 100%;
      text-align: center;
    }
  }

  .MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img {
    padding: 0;
  }

  .MuiPaper-root.MuiPaper-elevation {
    margin-bottom: 5px;

    @include respond-lg-screen {
      min-width: auto;
    }
  }

  .btn.btn-primary {
    @include respond-tab-land {
      font-size: 15px !important;
    }

    &.btn-browse-more {
      font-size: 16px !important;
      border-radius: 36px !important;
    }
  }
}

.css-1qvr50w-MuiTypography-root,
.MuiPaper-root {
  font-family: $primary-font !important;
}

.css-1ldzeor-MuiPaper-root-MuiCard-root {
  border: 1px solid #d5d5d5;
  border-radius: 13px;
}

.MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img {
  padding: 0 20px;
}

.MuiCardHeader-root {
  text-align: center;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 0.9375rem;
  font-family: $primary-font !important;
  height: 75px;

  span {
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--color-primary);
    font-family: $primary-font !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}

.list-products .MuiCardContent-root {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;

  p {
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-secondary);
    font-family: $primary-font !important;
    display: flex;
    justify-content: center;
    margin-bottom: 0 !important;
    text-align: center;
    font-size: 0.8rem;
  }
}

.MuiCardActions-root {
  justify-content: center;

  button {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: capitalize;
    font-family: $primary-font !important;
    color: var(--color-grey);
    min-width: auto;

    span {
      margin: 0 !important;
    }
  }
}

.MuiTooltip-tooltip {
  max-width: 200px !important;
  padding: 5px 10px !important;
  font-size: 13px !important;
}