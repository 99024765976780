@import "./Scss/mixins";

.product-container.embed-product-container {
  background-color: #f6f6f6;
  width: 100%;
  min-height: 100%;
  padding: 50px;
  margin-top: 0px;

  @include respond-tab-land {
    margin-top: 156px;
  }

  @media screen and (max-width: 900px) {
    padding: 30px;
  }

  @include respond-tab-port {
    margin-top: 0;
  }

  @include respond-md-phone {
    padding: 25px;
  }

  .product-nav-btn {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;

    button {
      margin-right: 10px;

      svg {
        font-size: 1rem !important;
        margin-left: 5px;
      }
    }

    .btn-more-product {
      background-color: var(--color-grey);
    }

    @media screen and (max-width: 900px) {
      margin-bottom: 10px;
    }

    @include respond-xs-phone {
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
  .btn-goback-container{
    .btn-primary.btn-normal{
      line-height: 1.25 !important;
      height: 32px;
    }
  }
    
}