@import "../Scss/variables.scss";
@import "../Scss/mixins";

header.app-header {
  background-color: var(--color-white);
  height: 60px;
  border-bottom: 1px solid #bbb;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    align-items: start;
  }

  @include respond-tab-port {
    position: fixed;
    z-index: 2;
    padding: 0 20px;
  }

  @include respond-xs-phone {
    justify-content: normal;
  }

  .responsiveNav {
    display: flex;
    position: absolute;
    z-index: 4;
    right: 0px;
    top: 0;
    justify-content: right;

    @include respond-md-desk {
      color: var(--color-primary);
      vertical-align: middle;
      align-items: center;
    }

    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
      min-height: 48px !important;
      display: none;

      @media screen and (max-width: 1199px) {
        display: flex;
      }

      @include respond-sm-phone {
        min-height: 48px !important;
      }
    }
  }
}

.box-container {
  background-color: #fff;
  margin-top: -28px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-md-phone {
    flex-direction: column;
    width: 100%;
  }

  .login-lang-container {
    display: flex;
    display: -ms-flexbox;
    color: var(--color-primary);
    width: 25%;
    justify-content: flex-end;
    align-items: center;

    @include respond-md-phone {
      width: 100%;
      margin-top: 10px;
    }

    @include respond-md-phone {
      justify-content: flex-end;
      height: auto;
      flex-direction: column;
    }

    & > div {
      justify-content: end;

      @include respond-tab-land {
        min-width: 70px;
      }

      @include respond-md-phone {
        min-width: auto;
      }
    }

    .login {
      font-size: 0.8rem;
    }
  }
}

.search-container {
  width: 35%;

  .MuiAutocomplete-endAdornment {
    display: none
  }

  @media screen and (max-width:1260px) {
    width: 30%;
  }

  .search-container.MuiAutocomplete-root {
    width: 100%;
    display: inline-flex;
    border: 1px solid var(--color-primary);
    margin-right: 10px !important;
    align-items: center;
    border-radius: 26px;

    @include respond-md-phone {
      width: 100%;
    }

    input {
      padding: 7px 14px;
      color: var(--color-primary);

      @include respond-sm-phone {
        padding: 8px 12px;
        color: var(--color-primary);
        font-size: 16px;
      }
    }

    button {
      color: var(--color-primary);
      background-color: #fff;
    }

    .MuiSvgIcon-root {
      width: 1.11em;
      height: 1.11em;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0 none !important;
    }

    .MuiOutlinedInput-root.MuiInputBase-root {
      padding: 0;
    }

    .MuiInputLabel-root.MuiInputLabel-formControl {
      top: -7px;
      font-size: 0.8rem;
    }

    &.Mui-focused {

      .MuiInputLabel-root.MuiInputLabel-formControl,
      .MuiInputLabel-root.MuiInputLabel-formControl.MuiFormLabel-filled {
        top: 0;
        background-color: #fff;
      }
    }

    .MuiInputLabel-root.MuiInputLabel-formControl.MuiFormLabel-filled {
      top: 0;
      background-color: #fff;
    }

    ul {
      li {
        border-bottom: 1px solid var(--color-grey);

        .suggested-value {
          width: 80%;
        }

        .suggest-code {
          width: 20%;
        }
      }
    }
  }
}

.responsive-nav-container {
  z-index: 1 !important;

  .MuiPaper-root.MuiPaper-elevation {
    top: 60px;
    width: 100% !important;
    height: 240px;

    @media screen and (max-width: 1199px) {
      height: 60px;
      display: flex;
      justify-content: center;
      padding: 0 20px;
    }

    @include respond-tab-port {
      height: 60px;
    }

    @include respond-md-phone {
      height: 260px;
    }

    @include respond-xs-phone {
      height: 300px;
    }
  }
}

.without-outline-select {
  flex-direction: row !important;

  fieldset {
    border: 0 none;
  }

  .MuiSelect-select {
    align-items: center;
    display: inline-flex;
    font-weight: 500;
    color: var(--color-primary);
    padding: 6px !important;
    text-transform: uppercase;
    font-size: 16px;
    font-family: $primary-font;
  }

  .MuiSvgIcon-root {
    font-size: 1.7rem;
    color: rgb(0, 68, 138);
    right: 0;
    padding-right: 8px !important;

    &.language-icon {
      padding-right: 0 !important;
      width: 24px;
      height: 24px;
    }
  }

  p {
    display: inline-flex;
    box-sizing: border-box;
    color: var(--color-primary);
    text-transform: uppercase;
    align-items: center;
  }

  li {
    color: #999;
    font-weight: 700;
    border-top: 1px solid #c5c5c5;
    background-color: #fff;
    height: 2.5em;
    line-height: 2.5em;
    padding-left: 2em;
  }
}

[data-testid="ArrowDropDownCircleIcon"] {
  font-size: 1.25rem !important;
  padding: 0 !important;
}

.user-name-placeholder {
  font-size: 0.875rem;
  color: var(--color-primary);
  font-family: $primary-font;

  .MuiAvatar-root.MuiAvatar-circular {
    background-color: var(--color-primary);
    width: 30px;
    height: 30px;
  }
}

.ant-select-dropdown {
  background-color: #fff;
  width: 320px !important;
  font-size: 13px;
}