@import "./Scss/variables.scss";
@import "./Scss/mixins.scss";

.list-products {
  display: grid;
  padding-top: 0;
  grid-template-columns: repeat(10, 10%);

  @include respond-huge-screen {
    grid-template-columns: repeat(6, 16.67%);
  }

  @include respond-laptop {
    grid-template-columns: repeat(6, 16.67%);
  }

  @include respond-md-desk {
    grid-template-columns: repeat(5, 20%);
  }

  @include respond-tab-land {
    grid-template-columns: repeat(5, 20%);
  }

  @include respond-tab-port {
    grid-template-columns: repeat(4, 25%);
  }

  @include respond-md-phone {
    grid-template-columns: repeat(3, 33.33%);
  }

  @include respond-sm-phone {
    grid-template-columns: repeat(2, 50%);
  }

  @include respond-xs-phone {
    grid-template-columns: repeat(1, 100%);
  }

  @mixin respond-sm-phone {
    display: flex;
    flex-direction: column;
  }

  .product-card-spacing {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.product-indicators {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  padding: 0px 16px;
  width: 100%;

  p,
  button {
    color: var(--color-grey);
  }

  p {
    line-height: 2;
    margin-bottom: 0;
  }
}

.browse-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filterDropdown {
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
    display: flex;
    padding: 0;
    padding-right: 5px;

    svg {
      margin-right: 5px;
      font-size: 1.2rem;
    }
  }

  fieldset {
    border: 0 none;
  }
}

.product-indicators {
  align-items: center;
  display: flex;

  .sorting-up-down {
    display: inline-flex;

    .MuiChip-filled {
      display: inline-flex;
    }
  }
}

.product-container {
  padding: 80px 40px !important;
  min-height:calc(100vh - 43px);

  &.embed-product-container {
    padding: 50px !important;
  }
}

nav.MuiPagination-root ul.MuiPagination-ul li .MuiButtonBase-root.MuiPaginationItem-root {
  font-size: 14px;
  height: auto;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  padding: 0;
}

.powered-text {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;

  button,
  a {
    padding: 0;
    font-weight: 500;
    font-family: "Ubuntu", sans-serif;
    font-size: .9rem;
    border-bottom: 1px solid var(--color-primary);
    border-radius: 0;
    color: var(--color-primary);
    line-height: 17px;
    margin-left: 4px;

    &:hover {
      color: var(--color-secondary);
      border-color: var(--color-secondary);
    }
  }
}