/*** Full-width ***/
@mixin full-width {
    width: 100%;
    float: left;
}

/*Media Query Manager
/* 
0 - 415 :          xs - phone
415 - 600:         sm - phone
600 - 767:         md - phone
767 - 991:         tab - port
991 - 1024         tab - land
1024 - 1200        md - desk
1200 - 1367:       laptop
1367 - 1800:       lg - screen
1800+:             huge - screen
*/
@mixin respond-xs-phone {
    @media (max-width: 415px) {
        @content
    }
}

@mixin respond-sm-phone {
    @media (max-width: 600px) {
        @content
    }
}

@mixin respond-md-phone {
    @media (max-width: 767px) {
        @content
    }
}

@mixin respond-tab-port {
    @media (max-width: 991px) {
        @content
    }
}

@mixin respond-tab-land {
    @media (max-width: 1024px) {
        @content
    }
}

@mixin respond-md-desk {
    @media (max-width: 1200px) {
        @content
    }
}

@mixin respond-lg-desk {
    @media (max-width: 1300px) {
        @content
    }
}

@mixin respond-laptop {
    @media (max-width: 1367px) {
        @content
    }
}

@mixin respond-lg-screen {
    @media (max-width: 1800px) {
        @content
    }
}

@mixin respond-huge-screen {
    @media (max-width: 1920px) {
        @content
    }
}