@import "../Scss/mixins";

.marketRegionPopup {
  h2 {
    color: var(--color-primary) !important;
    display: flex;
    justify-content: space-between;
    padding: 10px 40px !important;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0 !important;
    font-size: 1.2rem !important;

    @include respond-sm-phone {
      font-size: 1.2rem !important;
      padding-top: 20px !important;
    }
  }

  .lang-container {
    display: inline-flex;
    justify-content: end;
    float: right;
    margin: 0;
    align-items: center;

    .without-outline-select.lang-form [id="languageMenu"] {
      height: 30px;
    }

    label {
      color: var(--color-grey);
      font-size: 0.8rem;
    }

    svg {
      display: block !important;
      width: 20px;
      margin: 0;
      padding: 0 !important;
    }
  }

  .MuiDialogContent-root {
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow-x: hidden;

    @include respond-md-desk {
      flex-direction: column;
      margin: 15px;

      .browse-home-btn-container {
        margin-top: 10px;
      }
    }

    .MuiFormControl-root {
      margin: 35px 15px !important;
    }

    // .browse-home-btn-container {
    //   button {
    //     margin-top: 10px;
    //   }
    // }
    @include respond-sm-phone {
      padding: 5px;
      margin-top: 0;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: inherit;
    }

    .MuiFormControl-root {
      min-width: 230px;
      margin: 0 auto;

      @include respond-md-desk {
        width: 100%;
        min-width: auto;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-primary);
        top: 0;
      }

      .MuiSelect-select {
        padding: 8px 14px;
        min-height: auto;

        img {
          float: left;
          padding-right: 10px;
        }
      }

      [data-testid="ArrowDropDownCircleIcon"] {
        margin-right: 10px;
        color: var(--color-primary);
        width: 0.7em;
        height: 0.7em;
      }

      .MuiOutlinedInput-root {
        border-radius: 24px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .MuiButton-root {
      border-radius: 8px !important;

      svg[data-testid="ArrowCircleDownIcon"] {
        transform: rotate(270deg);
      }

      &:not(:first-child) {
        @include respond-sm-phone {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
}