@import "../Scss/mixins";

.logo-placeholder {
  display: flex;
  align-items: center;
  z-index: 3;
  padding-left: 0;

  @media screen and (max-width: 1199px) {
    margin-top: 0 !important;
  }

  @include respond-md-desk {
    display: flex;
    flex-direction: revert;
    align-items: center;
  }

  .project-name {
    border-left: 1px solid var(--color-primary);
    display: inline-flex;

    span,
    h1 {
      padding-left: 10px;
      color: var(--color-primary);
      font-weight: 500;
      font-family: "Ubuntu", sans-serif !important;
      font-size: 1rem !important;
      line-height: 1.5;
      margin: 0 !important;
    }
  }

  .logo-image {
    width: 100px;
  }

  .logo {
    max-width: 100%;
    max-height: 100%;
    height: auto;

    @include respond-md-desk {
      margin-top: 8px;
    }

    @include respond-tab-port {
      margin: 10px 0 0 0px;
    }

    @include respond-sm-phone {
      left: 20px;
      margin: 15px 0 0 15px;
    }
  }
}

.display-region {
  font-size: 16px !important;
  line-height: 18px !important;
  text-transform: uppercase;
  z-index: 5;
  color: rgba(55, 111, 167, 0.25);
  font-weight: 600 !important;

  @include respond-md-desk {
    margin-top: 0 !important;
  }
}

.logo-container {
  width: 25%;
  display: flex;
  display: -ms-flexbox;
  align-items: center;

  @include respond-laptop {
    width: 35%;
  }

  @include respond-md-desk {
    width: 100%;
  }

  @include respond-tab-land {
    width: 100%;
    display: block;
    position: fixed;
    background-color: #fff;
    z-index: 9;
    top: 0;
  }

  @include respond-md-phone {
    display: block;
    position: fixed;
    background-color: #fff;
    z-index: 9;
  }

  .logo-placeholder {
    .logo {
      position: absolute;
      margin: 15px 0 0 75px;
      height: 50px;
      width: 120px;
      top: 0;
      z-index: 4;

      @include respond-tab-port {
        margin: 15px 0 0 75px;
      }

      @include respond-sm-phone {
        left: 20px;
        margin: 15px 0 0 15px;
      }
    }
  }
}

.responsiveNav {
  .css-1mbzpyo-MuiButtonBase-root-MuiIconButton-root {
    @include respond-xs-phone {
      margin-right: 0;
      margin-top: -10px;
    }
  }
}