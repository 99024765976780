@import "./Scss/variables.scss";
@import "./Scss/mixins.scss";

.filter-container {
  position: fixed;
  left: 0;
  top: 0;
  padding: 60px 0 0 0;
  margin: 0;
  background-color: #fff;
  height: 100vh;
  z-index: 2;

  @include respond-tab-land {
    position: relative;
    height: auto;
    z-index: 0;
  }

  [data-simplebar="init"] {
    height: 83vh;

    @include respond-tab-land {
      height: auto !important;
    }
  }

  svg {
    font-size: 1.2rem;
  }

  p {
    position: relative;
    margin-bottom: 0px !important;

    .content-css {
      font-size: 0.875rem !important;
    }
  }

  h4 {
    font-weight: 500;
  }
}

.brand-info {
  margin-bottom: 15px;
  box-shadow: none !important;
  border-radius: 0 !important;
  border: 0 none;

  svg {
    margin-right: 10px;
  }

  .brandInfo-img-wrapper {
    height: 70px;
    display: block;

    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      margin: 0 auto;
      display: block;
    }
  }

  a {
    align-items: center;
    justify-content: left;
    display: flex;
    display: -ms-flexbox;
    color: var(--color-secondary);
    cursor: pointer;
  }

  .content-css {
    text-align: left !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: normal !important;
    color: #000 !important;
    line-height: 20px !important;

    a {
      color: var(--color-grey) !important;
    }

    .my-anchor-css-class {
      position: absolute;
      right: -10px;
      bottom: -2px;
    }
  }

  p {
    margin-bottom: 10px !important;
  }
}

.filter-container {
  .filter-header {
    background-color: #fff;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    font-size: 22px;

    .filter-title {
      color: var(--color-grey);
      text-transform: uppercase;
    }

    .filter-reset {
      font-size: 0.8rem;
      font-family: $primary-font !important;
      font-weight: 400;

      a {
        color: var(--color-secondary);
      }
    }
  }

  .filter-element {
    margin: 12px 0px 12px 0px !important;

    .MuiAccordionSummary-root {
      background-color: #00448a;
      color: var(--color-white);
      min-height: 28px;
      padding: 0px 8px;

      .MuiAccordionSummary-content {
        align-items: center;
        margin: 5px 0;
      }

      .MuiAccordionSummary-expandIconWrapper {
        color: var(--color-white);
      }
    }

    .filter-content {
      overflow-x: hidden !important;
      padding: 0;

      .filter-content-title {
        font-weight: bold;
        margin-top: 20px;
        cursor: pointer;
      }

      .MuiOutlinedInput-root {
        margin: 5px 0px 10px 0px;

        .MuiOutlinedInput-input {
          padding: 6px !important;
        }
      }

      .filter-content-item {
        display: flex;
        display: -ms-flexbox;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 16px;

        p {
          text-align: left;
        }
      }
    }

    &::before {
      display: none;
    }
  }
}

.browseBrands {
  .MuiSelect-select.MuiSelect-outlined {
    img {
      display: none;
    }
  }

  .filterDropdown {
    .MuiInputBase-formControl.chip-brand {
      width: 100%;
      text-align: center;
      justify-content: center;
      position: relative;
      height: 34.3px;
      padding: 0 !important;

      .MuiSelect-select.MuiSelect-outlined {
        padding: 0 !important;
        width: auto;
      }
    }
  }
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
  span.brandname {
    display: none;
  }
}