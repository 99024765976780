.filter-list {
    ul {
        padding: 0;
        list-style: none;
        display: flex;
        display: -ms-flexbox;
        flex-direction: column;
        margin-top: 0;

        li {
            justify-content: space-between;
            display: flex;
            display: -ms-flexbox;
            font-size: 18px;

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}

.filter-list-chips {
    .MuiChip-root {
        margin-bottom: 6px !important;
        margin-right: 6px;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: var(--color-white);
        height: 25px !important;

        .MuiSvgIcon-root {
            color: var(--color-white);
            font-size: .875rem;
        }
    }
}