.standard-form-control {
  width: 100%;

  .MuiInput-root {
    font-family: $primary-font;

    &:before {
      border: 1px solid var(--color-grey, 0.7);
      border-radius: 6px;
      padding: 10px;
    }

    .MuiInput-input {
      padding: 4px 15px 5px;
    }
  }

  .MuiInputLabel-root {
    font-family: $primary-font;
  }
}

form {
  [name="fullName"] {
    text-transform: capitalize;
  }

  label {
    font-family: $primary-font !important;
    font-weight: 400 !important;
    position: relative !important;
    color: #707070 !important;
    font-size: 16px !important;
    text-transform: capitalize;
    letter-spacing: 0.00938em !important;

    &.radiocheck-label {
      padding: 0;
      height: 42px;
      vertical-align: middle;
      line-height: 42px;
      margin-right: 20px;
    }
  }

  .form-group {
    margin-bottom: 25px;

    fieldset {
      border: 1px solid #707070;
      border-radius: 6px;
    }

    .MuiInputLabel-shrink {
      font-size: 20px !important;
      text-transform: capitalize;
      margin-bottom: -5px;
    }

    input.MuiInputBase-input {
      padding: 7px 14px;
    }

    .MuiInputBase-colorPrimary.MuiInputBase-formControl {
      p {
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
      }
    }

    fieldset legend span {
      display: none;
    }

    label.MuiFormControlLabel-root {
      margin-right: 20px;
    }

    .MuiSvgIcon-root[data-testid="ArrowDropDownCircleIcon"] {
      color: var(--color-primary);
      margin-right: 5px;
    }

    button {
      .MuiSvgIcon-root {
        color: var(--color-white);
        margin-right: 0;
        margin-left: 5px;
      }
    }

    .MuiSelect-root {
      padding: 7px 10px;
      max-height: 40px;
    }
  }

  .help {
    &.is-danger {
      color: #d32f2f;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin-top: 0;
      margin-right: 14px;
      margin-bottom: 0;
    }
  }

  .Mui-error {
    font-family: $primary-font !important;
  }

  .MuiFormLabel-asterisk.Mui-error {
    color: #707070;
  }

  .MuiSelect-icon {
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}