.disable-checkbox {
    color: var(--color-grey);

    .Mui-disabled {
        color: var(--color-grey) !important;
    }
}

.MuiFormControlLabel-root {
    span.MuiTypography-root {
        font-size: 0.875rem;
    }
}