nav {
    &.MuiPagination-root {
        ul.MuiPagination-ul {
            li {
                .MuiButtonBase-root.MuiPaginationItem-root {
                    width: 26px;
                    height: 26px;
                    font-family: $primary-font;
                    font-size: 22px;
                    color: var(--color-primary);
                    min-width: auto;

                    &.Mui-selected {
                        background-color: var(--color-primary);
                        color: var(--color-white);
                    }
                }
            }
        }
    }
}