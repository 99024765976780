@import "../Scss/mixins.scss";

/**************** Learn About BIM ****************/
.learn-about-bim {
  background-color: var(--color-white);
  padding: 50px 40px 0 40px;
  @include full-width;
  text-align: left;
  float: none;

  @include respond-tab-land {
    padding: 50px;
  }

  h2 {
    text-align: left;
  }

  .MuiPaper-root.MuiPaper-elevation {
    background-color: transparent;
    box-shadow: none;

    @include respond-md-desk {
      min-height: auto;
    }
  }

  .slick-track {
    display: flex !important;

    .slick-slide {
      margin-right: 30px;
      height: inherit !important;

      @include respond-tab-port {
        padding-right: 0;
      }

      @include respond-sm-phone {
        padding-right: 0;
      }

      .img-placeholder {
        height: 150px;
        background-position: top;
        display: flex;
        justify-content: center;

        img {
          height: auto;
          margin: 0 auto;
          max-width: 100%;
          vertical-align: middle;
          max-height: 100%;
        }
      }

      svg {
        transform: rotate(-55deg);
        font-size: 24px;
      }

      .slick-initialized .slick-slide {
        background-color: #ffffff;
      }

      .MuiPaper-root.MuiPaper-elevation {
        @include respond-tab-port {
          max-width: 300px !important;
        }
      }

      .MuiCardContent-root {
        padding: 10px;
        display: flex;
        background-color: var(--color-primary);
        color: #fff;

        svg {
          margin-right: 5px;
          margin-top: -2px;
        }
      }
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 25px;
    line-height: 1;
    opacity: 1;
    color: var(--color-primary);
  }

  .slick-next {
    right: 8px;
  }

  .slick-prev {
    left: -25px;
  }

  a {
    text-decoration: none;
    color: #707070;
    font-weight: 500;
    font-size: 0.875rem;
    width: 100%;
    display: block;
  }
}