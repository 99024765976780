.chip-brand {
    &:hover {
        img {
            transform: scale(1.2);
            background-color: #0000;
        }
    }

    &.chip-active {
        border: 1px solid var(--color-primary);

        img {
            transform: scale(1.2);
            background-color: #0000;
        }
    }
}