@import "../Scss/mixins.scss";
@import "../Scss/variables.scss";

.poc-dialog.MuiDialog-root.dialog-primary {
    .MuiDialogTitle-root {
        background-color: var(--color-primary);
        color: var(--color-white) !important;
        text-align: left;
        padding: 4px 15px !important;
        font-size: 18px !important;
    }

    .MuiDialog-container {
        .MuiDialog-paper {
            border: 2px solid var(--color-primary);
            box-shadow: none !important;
            background-color: var(--color-primary);
        }
    }

    .MuiDialogContent-root,
    .MuiDialogActions-root {
        background-color: var(--color-white);
    }

    .MuiDialogContent-root {
        border-bottom: 0 none;
    }

    .MuiDialogActions-root {
        padding: 0 16px 16px 16px;

        @include respond-md-phone {
            flex-direction: column;
        }

        .MuiButton-root.btn {
            font-size: 14px !important;
            font-weight: normal !important;
            padding: 5px 10px !important;
            min-width: 84px;
            line-height: 1.25;
            text-transform: capitalize;

            @include respond-sm-phone {
                margin: 0 0 10px 0;
            }
        }
    }
}

.MuiModal-root.MuiDialog-root .MuiBackdrop-root {
    background-color: rgba(197, 197, 197, .64) !important;
}

.MuiDialog-paper {
    border-radius: 6px !important;
    max-width: 45% !important;
    color: var(--color-grey);

    @include respond-md-phone {
        max-width: 90% !important;
    }

    @include respond-tab-port {
        max-width: 90% !important;
    }

    .MuiTypography-root.MuiTypography-h6 {
        text-align: left;
        color: var(--color-grey);
        font-size: 22px;
        padding: 15px 30px;
        font-weight: 400;
        margin-bottom: 0 !important;

        hr {
            margin: 20px 0 0 0;
        }
    }

    .MuiDialogActions-root {
        padding: 15px 30px 15px 15px;

        @include respond-sm-phone {
            flex-direction: column;

            button {
                margin: 0 0 10px 0 !important
            }
        }
    }

    .MuiDialogActions-root > :not(:first-of-type) {
        margin-left: 20px;
    }

    .MuiDialogContent-root {
        font-size: 0.875rem;
        padding: 15px 30px;

        p {
            font-size: 0.875rem;
            color: var(--color-grey);
            line-height: 20px;
        }
    }

    .MuiDialogTitle-root .MuiButton-root:not(:first-of-type) {
        margin-left: 20px;
    }
}

.roundedPopup {
    .MuiDialog-paper {
        border-radius: 10px !important;
        min-width: 40%;
        justify-content: center;
        border: 1px solid #707070;

        .MuiTypography-root.MuiTypography-h6 {
            text-align: center;
        }

        @include respond-tab-port {
            min-width: 85%;
        }

        @include respond-md-phone {
            min-width: 95%;
            padding: 10px;
        }

        @include respond-xs-phone {
            min-width: 98%;
        }
    }
}

.CookieSettingsClass {
    .MuiPaper-root {
        @include respond-tab-port {
            max-width: 90% !important;
        }

        .MuiDialogTitle-root {
            @include respond-md-phone {
                .MuiTypography-root.MuiTypography-h2 {
                    display: flex;
                    margin-top: 40px !important;
                    margin-bottom: 0 !important;
                }

                .float-right {
                    position: absolute;
                    right: 20px;
                    display: block;
                    top: 15px;

                    .btn.btn-primary.btn-popup {
                        min-width: auto;
                        margin-left: 5px;
                    }

                    .MuiButtonBase-root.MuiIconButton-root {
                        margin-left: 10px !important;
                    }
                }
            }
        }
    }
}

.MuiToggleButtonGroup-root {
    float: right;
    border-radius: 0;

    .MuiToggleButtonGroup-grouped {
        padding: 0;
        border: 0 none;
    }

    .MuiToggleButton-root {
        font-weight: 500;
        border-bottom: 2px solid transparent;
        font-size: .8rem;
        font-family: $primary-font;
        padding: 0 2px 0 2px;
        border-radius: 0 !important;
        line-height: 1.2;

        &.Mui-selected {
            color: #00448a;
            background-color: transparent;
            border-bottom-color: #00448a;

            &:hover {
                background-color: transparent;
            }
        }

        &.icon-placeholder {
            cursor: text;
            pointer-events: none;

            &:hover {
                background-color: transparent;
            }

            &.Mui-selected {
                color: rgba(0, 0, 0, 0.54);
                background-color: transparent;
                border-bottom-color: transparent;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}