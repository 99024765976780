@import "../Scss/variables.scss";
@import "../Scss/mixins.scss";

.mydownloads-container {
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  margin: 0 5px 0 0;

  @include respond-tab-land {
    margin-top: 0;
    width: auto;
  }

  @include respond-xs-phone {
    min-width: auto;
    justify-content: start;
  }

  a,
  a p,
  span {
    align-items: center;
    display: inline-flex;
    color: var(--color-primary);
    font-size: 0.8rem;
    font-weight: 500;
  }

  a p {
    @include respond-md-phone {
      display: none;
    }
  }

  .MuiSvgIcon-root {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    font-size: 1rem;

    @include respond-tab-land {
      margin-right: 0px;
    }
  }

  .my-download-text {
    display: inline-block;

    @media screen and (max-width:1367px) {
      display: none;
    }

    @include respond-md-phone {
      display: inline-block;
    }
  }
}