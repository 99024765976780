.brand-info {

    h3,
    strong {
        display: inline-block !important;
        margin: 0;
        font-size: 14px !important;
        line-height: 20px;
        text-transform: none;
        font-weight: normal;
    }

    .content-css {
        color: var(--color-grey) !important;
        position: relative;
        margin-bottom: 10px;

        span {
            margin-bottom: 10px !important;
            font-size: 0.875rem;
            line-height: 1.42857143;
            text-align: justify;
        }
    }

    a {
        word-break: break-all;
    }
}